export const useMailChimp = () => {
  const config = useRuntimeConfig();

  const submitForm = async (id: string, f_id: string, formData: FormData) => {
    await $fetch(`${config.public.mailChimpBaseFormUrl}&amp;id=${id}&amp;f_id=${f_id}`, {
      method: "POST",
      body: formData,
    });

    return true;
  };

  return {
    submitForm,
  };
};
