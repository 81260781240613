export const useFormUtils = () => {
  const validTextInput = computed(() => {
    return (value: string, length?: number): boolean => {
      const textLength = length || 0;
      return value.length > textLength;
    };
  });

  const validEmail = computed(() => {
    return (value: string): boolean => {
      const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
      return value.match(mailformat) ? true : false;
    };
  });

  const validPhone = computed(() => {
    return (value: string): boolean => {
      const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
      return re.test(value);
    };
  });

  const matchingEmails = (email1: string, email2: string): boolean => {
    return email1 === email2;
  };

  return {
    validTextInput,
    validEmail,
    validPhone,
    matchingEmails,
  };
};
