<script setup lang="ts">
import { ref, onMounted, onUnmounted } from "vue";

const props = defineProps({
  isAnimating: {
    type: Boolean,
    required: false,
    default: true,
  },
});

let lastAnimationCycle = false;
const animationCircle = ref<HTMLElement>();
const animationComplete = ref(false);
let animationTimeout: ReturnType<typeof setTimeout>;

onMounted(() => {
  (animationCircle.value as HTMLElement).style.animation =
    "rotate-circle 1.5s linear";
  animationCircle.value?.addEventListener("animationend", animateCircle);
});

function animateCircle() {
  if (animationCircle.value) {
    (animationCircle.value as HTMLElement).style.animation = ""; //classList.remove('is-animating');

    animationTimeout = setTimeout(() => {
      if (props.isAnimating && !lastAnimationCycle) {
        (animationCircle.value as HTMLElement).style.animation =
          "rotate-circle 1.5s linear";
      } else if (!lastAnimationCycle && !props.isAnimating) {
        lastAnimationCycle = true;
        (animationCircle.value as HTMLElement).style.animation =
          "rotate-circle 1s ease-out";
      } else {
        animationCircle.value?.removeEventListener(
          "animationend",
          animateCircle
        );
        animationComplete.value = true;
      }
    }, 5);
  }
}

onUnmounted(() => {
  clearInterval(animationTimeout);
});
</script>

<template>
  <div class="submit-animation">
    <div ref="animationCircle" class="animation-circle is-animating" />

    <div v-if="animationComplete" class="checkmark-container">
      <div class="checkmark" />
    </div>
  </div>
</template>

<style lang="scss">
.submit-animation {
  height: 105px;
  position: relative;
  width: 105px;
  .animation-circle {
    background-image: url(/assets/icons/submit-circle.svg);
    background-repeat: no-repeat;
    display: inline-block;
    height: 105px;
    width: 105px;
  }

  .checkmark-container {
    left: 20%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    .checkmark {
      animation: grow-checkmark 0.75s ease-in-out;
      background-image: url(/assets/icons/submit-checkmark-black.svg);
      background-repeat: no-repeat;
      background-size: 100%;
      height: 80px;
      width: 80px;
    }
  }

  @keyframes rotate-circle {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes grow-checkmark {
    0% {
      transform: scale(0.7);
    }
    70% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
}
</style>
