<template>
  <span class="error"><slot /></span>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/queries";
.error {
  color: red;
  display: inline-block;
  font-size: 1.25rem;
  padding: 0.25rem 0.5rem;
  width: 100%;

  @include queries.for-size(phone-only) {
    font-size: 16px;
  }
}
</style>
